(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/betslip-components/assets/javascripts/views/balance-view.js') >= 0) return;  svs.modules.push('/components/tipsen/betslip-components/assets/javascripts/views/balance-view.js');
"use strict";

const {
  Betslip
} = svs.components.tipsen.betslipComponents;
const {
  get
} = svs.components.account_balance;
const {
  useState
} = React;
const {
  Choose,
  When
} = svs.components.lbUtils.reactConditionals;
const {
  Currency: currency
} = svs.utils.format;
const {
  ReactIcon: Icon
} = svs.ui;
const {
  WrapLoginLink
} = svs.components.lbUtils.wrapLoginLink;
const {
  selectActiveWallet,
  selectCommissionOptions
} = svs.components.tipsen.walletSelectors;
const {
  useSelector
} = ReactRedux;
const {
  useMediaQuery,
  breakpoints,
  BREAKPOINT_MD
} = svs.components.lbUtils.useMediaQuery;
const {
  WALLET_PLAYER
} = svs.components.tipsen.wallet.walletType;
const roles = [svs.core.userSession.roles.PLAYER, svs.core.userSession.roles.RETAILER]; 

const isRetailer = svs.core.userSession.hasRole(svs.core.userSession.roles.RETAILER);
const isAnonymous = svs.core.userSession.isAnonymous();
const RetailerLabels = _ref => {
  let {
    commissionPercent
  } = _ref;
  const commissionItems = useSelector(selectCommissionOptions);
  return React.createElement(React.Fragment, null, React.createElement("span", {
    className: "pg_betslip__balance_text"
  }, "Avgift: ".concat(commissionPercent, "%")), React.createElement("span", {
    className: "pg_betslip__balance_text"
  }, "Spelpott: ".concat(currency(commissionItems[commissionPercent].amount), " kr")));
};
const BalanceView = () => {
  var _activeWallet$meta, _activeWallet$meta2;
  const isSmallDevice = useMediaQuery(breakpoints.down(BREAKPOINT_MD));
  const [balance, setBalance] = useState();
  const [showBalance, setShowBalance] = useState(false);
  const activeWallet = useSelector(selectActiveWallet);
  const isPlayerWallet = (activeWallet === null || activeWallet === void 0 ? void 0 : activeWallet.type) === WALLET_PLAYER;
  const handleClick = () => {
    if (!showBalance && isPlayerWallet) {
      get(true, newBalance => setBalance(newBalance));
    }
    if (!isAnonymous) {
      setShowBalance(!showBalance);
    }
  };
  const qs = new URLSearchParams(window.location.search);
  const returnUrl = "".concat(window.location.pathname, "?").concat(qs);
  const walletBalance = currency(isPlayerWallet ? balance : activeWallet === null || activeWallet === void 0 || (_activeWallet$meta = activeWallet.meta) === null || _activeWallet$meta === void 0 ? void 0 : _activeWallet$meta.Balance);
  return React.createElement(WrapLoginLink, {
    returnUrl: returnUrl,
    roles: roles
  }, React.createElement("div", {
    className: "pg_betslip__balance",
    onClick: handleClick
  }, React.createElement(Choose, null, React.createElement(When, {
    condition: showBalance
  }, React.createElement("div", {
    className: "pg_betslip__balance_label ".concat(isRetailer ? 'pg_betslip__balance_label--retailer' : '')
  }, React.createElement(Choose, null, React.createElement(When, {
    condition: !isRetailer
  }, !isPlayerWallet && React.createElement("span", {
    className: "pg_betslip__balance_text"
  }, activeWallet === null || activeWallet === void 0 ? void 0 : activeWallet.label), React.createElement(Icon, {
    className: "pg_betslip__balance_icon",
    color: "fc-white",
    icon: "balance",
    size: isSmallDevice ? '100' : '200'
  }), React.createElement("span", {
    className: "pg_betslip__balance_text margin-left"
  }, "".concat(walletBalance, " kr"))), React.createElement(When, {
    condition: isRetailer
  }, React.createElement(RetailerLabels, {
    commissionPercent: activeWallet === null || activeWallet === void 0 || (_activeWallet$meta2 = activeWallet.meta) === null || _activeWallet$meta2 === void 0 ? void 0 : _activeWallet$meta2.commissionPercent
  }))))), React.createElement(When, {
    condition: !showBalance
  }, React.createElement("div", {
    className: "pg_betslip__balance__hide"
  }, React.createElement(Icon, {
    className: "pg_betslip__balance__hide__icon",
    color: "fc-white",
    icon: "balance",
    size: isSmallDevice ? '100' : '200'
  }), React.createElement("span", {
    className: "margin-left pg_betslip__balance__hide__label"
  }, isRetailer ? 'Visa spelpott' : 'Visa saldo'))))));
};
Betslip.BalanceView = BalanceView;

 })(window);