(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/betslip-components/assets/javascripts/views/parts/progress-display.js') >= 0) return;  svs.modules.push('/components/tipsen/betslip-components/assets/javascripts/views/parts/progress-display.js');
"use strict";

const {
  Betslip
} = svs.components.tipsen.betslipComponents;
const ProgressDisplay = _ref => {
  let {
    eventsStarted,
    maxEvents
  } = _ref;
  return React.createElement("div", {
    className: "pg_betslip__progress_display"
  }, eventsStarted, ' ', "av", ' ', maxEvents);
};
Betslip.ProgressDisplay = ProgressDisplay;

 })(window);