(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/betslip-components/assets/javascripts/betslip-label.js') >= 0) return;  svs.modules.push('/components/tipsen/betslip-components/assets/javascripts/betslip-label.js');
"use strict";

const {
  useBetslipContext,
  Betslip
} = svs.components.tipsen.betslipComponents;
const {
  BetslipCouponType
} = svs.components.tipsen.engine.constants;
const {
  competitionHandler
} = svs.components.marketplaceCompetition.competitionHandler;
const {
  useSelector
} = ReactRedux;
const {
  selectCoupon
} = svs.components.tipsen.engine.selectors.couponSelectors;
const BetslipLabel = () => {
  var _useSelector;
  const context = useBetslipContext();
  const {
    couponType,
    couponId
  } = context;
  let label;
  const productId = (_useSelector = useSelector(state => selectCoupon(state, couponId))) === null || _useSelector === void 0 ? void 0 : _useSelector.productId;
  const activeCompetition = competitionHandler().getActiveCompetition(productId);
  let compDesc;
  if (activeCompetition) {
    compDesc = activeCompetition.getCompetitionDesc();
  }
  switch (couponType) {
    case BetslipCouponType.Standard:
      label = 'Standard';
      break;
    case BetslipCouponType.ReduceraFritt:
      label = 'Reducera fritt';
      break;
    case BetslipCouponType.Enkelrader:
      label = 'Enkelrader';
      break;
    case BetslipCouponType.ExternaSystemspel:
      label = 'Externa systemspel';
      break;
    case BetslipCouponType.Competition:
      label = compDesc;
      break;
    default:
      label = null;
  }
  return React.createElement("span", {
    className: "pg_betslip_system_label"
  }, label);
};
Betslip.BetslipLabel = BetslipLabel;

 })(window);