(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/betslip-components/assets/javascripts/use-betslip-context.js') >= 0) return;  svs.modules.push('/components/tipsen/betslip-components/assets/javascripts/use-betslip-context.js');
"use strict";

const {
  useContext
} = React;
const {
  BetslipContext
} = svs.components.tipsen.betslipComponents;
const useBetslipContext = () => {
  const context = useContext(BetslipContext);
  if (!context) {
    throw new Error('Betslip components must be mounted inside betslip context');
  }
  return context;
};
setGlobal('svs.components.tipsen.betslipComponents.useBetslipContext', useBetslipContext);

 })(window);