(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/betslip-components/assets/javascripts/views/rows-view-reduce.js') >= 0) return;  svs.modules.push('/components/tipsen/betslip-components/assets/javascripts/views/rows-view-reduce.js');
"use strict";

const {
  useBetslipContext,
  Betslip
} = svs.components.tipsen.betslipComponents;
const {
  useSelector
} = ReactRedux;
const {
  selectSystemSize
} = svs.components.tipsen.engine.selectors.couponSelectors;
const {
  Currency: currency
} = svs.utils.format;
const Rows = _ref => {
  let {
    isExtraSmall,
    numberOfRows,
    selectedSystemSize
  } = _ref;
  if (isExtraSmall) {
    return React.createElement(React.Fragment, null, React.createElement("span", null, React.createElement("span", null, numberOfRows)), React.createElement("span", null, ' ', "av", ' ', selectedSystemSize));
  }
  return React.createElement(React.Fragment, null, React.createElement("span", null, React.createElement("span", null, numberOfRows)), React.createElement("span", {
    className: "pg_betslip_row_value__selected_size"
  }, ' ', "av", ' ', selectedSystemSize));
};
const RowsViewReduce = _ref2 => {
  let {
    numberOfRows,
    numRows,
    children
  } = _ref2;
  const {
    isExtraSmall,
    couponId
  } = useBetslipContext();
  const selectedSystemSize = useSelector(state => selectSystemSize(state, couponId, 0));
  const ariaLabel = selectedSystemSize > 0 ? "".concat(numRows, " rader av ").concat(selectedSystemSize) : "".concat(numRows, " rader");
  numberOfRows = currency(numberOfRows);
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "pg_tipsen_betslip_system"
  }, children), React.createElement(Betslip.RowsDisplay, {
    ariaLabel: ariaLabel,
    label: "Rader"
  }, React.createElement(Rows, {
    isExtraSmall: isExtraSmall,
    numberOfRows: numberOfRows,
    selectedSystemSize: selectedSystemSize
  })));
};
Betslip.RowsViewReduce = RowsViewReduce;

 })(window);