(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/betslip-components/assets/javascripts/views/rows-view.js') >= 0) return;  svs.modules.push('/components/tipsen/betslip-components/assets/javascripts/views/rows-view.js');
"use strict";

const {
  useBetslipContext,
  Betslip
} = svs.components.tipsen.betslipComponents;
const Rows = _ref => {
  let {
    isExtraSmall,
    numberOfRows
  } = _ref;
  if (isExtraSmall) {
    return React.createElement(React.Fragment, null, React.createElement("span", null, numberOfRows), React.createElement("span", null, " st"));
  }
  return React.createElement("span", null, React.createElement("span", null, numberOfRows), React.createElement("span", null, " st"));
};
const RowsView = _ref2 => {
  let {
    label,
    numberOfRows,
    numRows,
    children
  } = _ref2;
  const {
    isExtraSmall
  } = useBetslipContext();
  const ariaLabel = "".concat(numRows, " rader");
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "pg_tipsen_betslip_system"
  }, children), React.createElement(Betslip.RowsDisplay, {
    ariaLabel: ariaLabel,
    label: label
  }, React.createElement(Rows, {
    isExtraSmall: isExtraSmall,
    numberOfRows: numberOfRows
  })));
};
Betslip.RowsView = RowsView;

 })(window);