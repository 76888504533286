(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/betslip-components/assets/javascripts/views/parts/pay-button-display.js') >= 0) return;  svs.modules.push('/components/tipsen/betslip-components/assets/javascripts/views/parts/pay-button-display.js');
"use strict";

const {
  ReactButton,
  ReactIcon
} = svs.ui;
const {
  Betslip
} = svs.components.tipsen.betslipComponents;
const PayButtonDisplay = _ref => {
  let {
    betslipLabel,
    handlePay,
    hasScrollDownIcon,
    isDisabled,
    isFocused = true
  } = _ref;
  return React.createElement(ReactButton, {
    block: true,
    "data-testid": "payButton",
    disabled: isDisabled,
    focus: isFocused,
    iconPosition: "left",
    onClick: handlePay,
    type: "button"
  }, betslipLabel, hasScrollDownIcon ? React.createElement(ReactIcon, {
    className: "margin-left",
    color: "white",
    icon: "scroll-down"
  }) : '');
};
Betslip.PayButtonDisplay = PayButtonDisplay;

 })(window);