(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/betslip-components/assets/javascripts/views/pay-button.js') >= 0) return;  svs.modules.push('/components/tipsen/betslip-components/assets/javascripts/views/pay-button.js');
"use strict";

var _svs$core$config$data, _svs$core$config$data2;
const {
  useContext,
  useEffect,
  useState
} = React;
const {
  useIsAtBottomWindow
} = svs.components.tipsen.hooks;
const {
  useSelector
} = ReactRedux;
const {
  selectActiveWallet
} = svs.components.tipsen.walletSelectors;
const {
  PaymentContext
} = svs.components.tipsen.couponId;
const {
  Betslip
} = svs.components.tipsen.betslipComponents;
const {
  selectIsKeyLoading
} = svs.components.tipsen.loadingState.selectors;
const {
  LoadingKeys
} = svs.components.tipsen.loadingState.constants;
const {
  useIsCurrentDrawPlayable
} = svs.components.tipsen.hooks;
const isSelfService = (_svs$core$config$data = (_svs$core$config$data2 = svs.core.config.data.svsconfig) === null || _svs$core$config$data2 === void 0 ? void 0 : _svs$core$config$data2.isSelfService) !== null && _svs$core$config$data !== void 0 ? _svs$core$config$data : false;
const {
  getLogger
} = svs.core.log;
const wagerLogger = getLogger('tipsen:wager');
const {
  WrapLoginLink
} = svs.components.lbUtils.wrapLoginLink;
const {
  actions,
  PARAM_ACTION
} = svs.components.sport.tipsenShared;
const roles = [svs.core.userSession.roles.PLAYER, svs.core.userSession.roles.RETAILER]; 

const defaultReturnRoles = [];
const payButtonScrollEvent = new CustomEvent('payButtonScroll');
const PayButton = () => {
  const isDrawPlayable = useIsCurrentDrawPlayable();
  const [hasAdditions, setHasAdditions] = useState(true);
  const isLoggedIn = !svs.core.userSession.isAnonymous();
  const activeWallet = useSelector(selectActiveWallet);
  const placeBet = useContext(PaymentContext);
  const isAtBottom = useIsAtBottomWindow();
  const isLoadingPix = useSelector(state => selectIsKeyLoading(state, LoadingKeys.PIX));
  const isDisabled = () => {
    if (!isDrawPlayable || isLoadingPix) {
      return true;
    }
    if (isLoggedIn) {
      return !activeWallet;
    }
    return false;
  };
  const isScrollToBottomNeeded = hasAdditions && !isAtBottom;
  const handlePay = () => {
    if (isScrollToBottomNeeded) {
      window.dispatchEvent(payButtonScrollEvent);
      window.scrollTo({
        left: 0,
        top: document.body.scrollHeight,
        behavior: 'smooth'
      });
    } else {
      wagerLogger.info('PlaceBet button clicked');
      placeBet();
    }
  };
  let betslipLabel = 'Betala';
  if (isSelfService) {
    betslipLabel = 'Lägg i varukorgen';
  } else if (isScrollToBottomNeeded) {
    betslipLabel = 'Till betalning';
  }
  useEffect(() => {
    const additions = document.querySelector('.tipsen-additions');
    if (!additions) {
      setHasAdditions(false);
    }
  }, []);
  const qs = new URLSearchParams(window.location.search);
  qs.set(PARAM_ACTION, actions.PAY);
  const returnUrl = "".concat(window.location.pathname, "?").concat(encodeURIComponent(qs));

  const isFocused = isSelfService ? !isScrollToBottomNeeded : true;
  return React.createElement(WrapLoginLink, {
    classes: "pg_betslip__login_button",
    isDisabledWrapper: isScrollToBottomNeeded,
    returnUrl: returnUrl,
    roles: isScrollToBottomNeeded ? defaultReturnRoles : roles
  }, React.createElement(Betslip.PayButtonDisplay, {
    betslipLabel: betslipLabel,
    handlePay: handlePay,
    hasScrollDownIcon: isScrollToBottomNeeded,
    isDisabled: isDisabled(),
    isFocused: isFocused
  }));
};
Betslip.PayButton = PayButton;

 })(window);