(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/betslip-components/assets/javascripts/views/progress-bar.js') >= 0) return;  svs.modules.push('/components/tipsen/betslip-components/assets/javascripts/views/progress-bar.js');
"use strict";

const {
  Betslip,
  useBetslipContext
} = svs.components.tipsen.betslipComponents;
const {
  ProgressBar
} = svs.components.lbUi.progressBar;
const className = 'pg_betslip__progress_bar';
const BetslipProgressBar = () => {
  const context = useBetslipContext();
  const {
    couponEventsStarted,
    couponEventCount
  } = context;
  return React.createElement("div", {
    className: "pg_betslip__progress"
  }, React.createElement(ProgressBar, {
    className: className,
    currValue: couponEventsStarted,
    maxValue: couponEventCount,
    minValue: 0
  }), React.createElement(Betslip.ProgressDisplay, {
    eventsStarted: couponEventsStarted,
    maxEvents: couponEventCount
  }));
};
Betslip.ProgressBar = BetslipProgressBar;

 })(window);