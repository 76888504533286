(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/betslip-components/assets/javascripts/views/parts/rows-display.js') >= 0) return;  svs.modules.push('/components/tipsen/betslip-components/assets/javascripts/views/parts/rows-display.js');
"use strict";

const {
  Betslip
} = svs.components.tipsen.betslipComponents;
const {
  ReactIcon
} = svs.ui;
const RowsDisplay = _ref => {
  let {
    children,
    label = 'Rader',
    ariaLabel
  } = _ref;
  return React.createElement("div", {
    "aria-label": ariaLabel,
    className: "pg_betslip_rows"
  }, React.createElement("span", {
    className: "pg_betslip_row_title"
  }, label, ' '), React.createElement(ReactIcon, {
    color: "white",
    icon: "rows-three",
    size: "100"
  }), React.createElement("span", {
    className: "pg_betslip_row_value"
  }, children));
};
Betslip.RowsDisplay = RowsDisplay;

 })(window);